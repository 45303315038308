import React, { useState } from "react"
import SearchBar from "./searchbar"
import PropTypes from "prop-types"

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

const Header = ({homepageHeader}) => {
  const [state, setState] = useState({
    mobSearchActive: false,
  })

  function handleClick(e) {
    setState({
      mobSearchActive: !state.mobSearchActive
    })
    if (typeof window !== "undefined") {
      state.mobSearchActive ?
        document.querySelector(".main-container").className = "main-container"
        : document.querySelector(".main-container").className = "main-container search-on"
    }
  }
  
  return(
    <>
      <header className={state.mobSearchActive ? "header header-fixed" : "header"}>
        <nav className={homepageHeader ? 
          "navbar navbar-home navbar-expand-lg navbar-light" 
          : "navbar navbar-expand-lg navbar-light"}
        >
          <div className="container">
            <div className="row">
              <div className="brandlogo col-4">
                <a className="navbar-logo" target="_blank" href="https://furrble.com" rel="noreferrer">
                  <img src="/images/favicon.png" alt="furrble logo" />
                </a>
              </div>

              <div className="blogtitle col-4">
                {homepageHeader ?
                  ``
                  : <a href="/" className="blog-logo">
                      <span className="title">Fables</span>
                    </a>
                }
              </div>

              <div className="searchbar col-4">
                <SearchBar />
                <button className="btn-search my-2 my-sm-0 searchbar-mob-toggle" onClick={
                  e => {handleClick(e)}
                }>
                  <FontAwesomeIcon icon={[`fas`, `search`]} />
                </button>
              </div>
            </div>

          </div>
        </nav>
      {
        state.mobSearchActive ?
        <>
          <div className="searchbar-mob">
            <SearchBar />
          </div>
          <div className="search-backdrop" aria-hidden="true" onClick={e => {handleClick(e)}} onKeyDown={e => {handleClick(e)}}></div>
        </>
        : ``
      }
      </header>
    </>
  )
}

Header.defaultProps = {
  homepageHeader: false,
}

Header.propTypes = {
  homepageHeader: PropTypes.bool,
}

export default Header 
