import React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fab, fas);

const Footer = () => (
  <footer className="page-footer black-text">
    <div className="footer-head">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-12">
            <h6 className="black-text">Find us online</h6>
            <ul className="social-icons">
              <li>
                <a className="social-icon" target="_blank" rel="noreferrer" href="https://www.facebook.com/furrble/">
                  <FontAwesomeIcon icon={[`fab`, `facebook-square`]} size="2x" />
                </a>
              </li>
              <li>
                <a className="social-icon" target="_blank" rel="noreferrer" href="https://www.instagram.com/furrble/">
                  <FontAwesomeIcon icon={[`fab`, `instagram`]} size="2x" />
                </a>
              </li>
              <li>
                <a className="social-icon" target="_blank" rel="noreferrer"  href="https://www.linkedin.com/company/furrble/">
                  <FontAwesomeIcon icon={[`fab`, `linkedin`]} size="2x" />
                </a>
              </li>
              <li>
                <a className="social-icon" target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCqwWxxaZ_rznh4TXC1H_lWQ">
                  <FontAwesomeIcon icon={[`fab`, `youtube`]} size="2x" />
                </a>
              </li>
              <li>
                <a className="social-icon" target="_blank" rel="noreferrer" href="https://twitter.com/furrble_in">
                  <FontAwesomeIcon icon={[`fab`, `twitter`]} size="2x" />
                </a>
              </li>
            </ul>

            <img alt="Furrble Logo" src={`/images/logo.png`} className="footer-logo" />

          </div>
          <div className="col-12 col-lg-2 col-sm-12">
            <h6 className="footer-link-label">Company</h6>
            <ul>
              <li><a className="black-text" href="https://furrble.com/about">About</a></li>
              <li><a className="black-text" href="https://careers.smartrecruiters.com/Furrble">Careers</a></li>
              <li><a className="black-text" href="#!">Be a Partner</a></li>
              <li><a className="black-text" href="#!">Community Service</a></li>
            </ul>
          </div>
          <div className="col-12 col-lg-2 col-sm-12">
            <h6 className="footer-link-label">Resources</h6>

            <ul>
              <li><a className="black-text" href="#!">Our Partners</a></li>
              <li><a className="black-text" href="https://furrble.com/faq">FAQs</a></li>
              <li><a className="black-text" href="#!">Hardware</a></li>
              <li><a className="black-text" href="#!">Patents</a></li>
            </ul>
          </div>
          <div className="col-12 col-lg-2 col-sm-12">
            <h6 className="footer-link-label">Legal</h6>

            <ul>
              <li><a className="black-text" href="https://furrble.com/privacy">Privacy Policy</a></li>
              <li><a className="black-text" href="#!">Terms</a></li>
              <li><a className="black-text" href="#!">Compliance</a></li>
              <li><a className="black-text" href="#!">Contact</a></li>
            </ul>

          </div>
        </div>
      </div>
    </div>
    <div className="footer-copyright">
      <div className="container">
        <div className="row">
          <p className="black-text">&copy; 2020 Furrble Technologies Private Limited. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
