/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import 'bootstrap/dist/css/bootstrap.css';
import "./css/style.css"

const Layout = ({ header, children }) => {
  return (
    <>
      <Header homepageHeader={ header === "home" ? true : false }/>
      <div className="main-container">
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.defaultProps = {
  header: `primary`,
}

Layout.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
