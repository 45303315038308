import React, { useState } from "react"
import { useFlexSearch } from "react-use-flexsearch"
import { useStaticQuery, graphql, Link } from "gatsby"

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

const SearchBar = () => {
	const data = useStaticQuery(graphql`
			{
				localSearchPosts {
					index
					store
				}
			}
		`
	)

	const [state, setState] = useState({searchQuery: "", isLoading: false})
	const results = useFlexSearch(state.searchQuery, data.localSearchPosts.index, data.localSearchPosts.store, {
		suggest: true,
		limit: 6
	})

	return (
		<>
			<form className="nav-search input-group" action={"/posts"} method="get" onSubmit={ (e) => {
				console.log('form submitted')
			}}>
				<input className="form-control" id="search-input" placeholder="Search" aria-label="Search bar" aria-describedby="button-search" name="search" value={state.searchQuery}
					onChange={ e => {
						e.preventDefault()
						setState({searchQuery: e.target.value})
					}}
					required
				/>
				<div className="input-group-append">
					<button className="btn-search" type="submit" id="button-search">
						<FontAwesomeIcon icon={[`fas`, `search`]} />
					</button>
				</div>
				<ul className={results.length === 0 ? "search-results" : "search-results show"}>
					{results.map(result => (
						<li className="search-result" key={result.id}>
							<Link to={"/post/" + result.slug}>{result.title}</Link>
						</li>
					))}
				</ul>
			</form>
		</>
	)
}

export default SearchBar